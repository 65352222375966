import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import * as yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import { PostAssociateContract } from '../../../API/Contracts/ContractsAPI';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { ContractsRefreshContext } from '../../../Contexts/ContractsContext';

import BaseButton from '../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import TextInput from '../../../Components/Inputs/TextInput';
import SelectInput from '../../../Components/Inputs/SelectInput';
import { history } from '../../../Routes/history';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { fullName } from '../../../Utils/Format/Names';
import {
  ICON_SELECT_OPTIONS,
  INVOICE_CONTRACTS,
  DEFAULT_ALIASES
} from '../contract_enums';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';

const editContractSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono')
});

const ContractAssociationDialogGDG = props => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const { contract, open, handleClose, setAlert } = props;
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [aliasName, setAliasName] = useState('');
  const [aliasIcon, setAliasIcon] = useState(1);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [dirty, setDirty] = useState({ aliasName: null });

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * FUNCTIONS
  const handleAssociate = useCallback(
    async event => {
      event.preventDefault();

      setLoading(true);
      setCanSubmit(false);

      const associationData = {
        alias: aliasName.trim(),
        icon: aliasIcon,
        sendInvoice: false,
        sendEmailInvoice: false,
        origin: {
          url: window.location.pathname
        }
      };

      const recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.AssociateContract
      );

      const response = await PostAssociateContract(
        authToken,
        contract.id,
        associationData,
        recaptchaToken
      );
      if (response.success) {
        handleClose();
        setAlert({
          type: 'success',
          message: `Se ha asociado ${Company.contractConjugation.regular.singular.article} "${aliasName}"`
        });
        refreshContracts();
        history.push('/');
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [
      authToken,
      contract,
      aliasIcon,
      aliasName,
      refreshContracts,
      setAlert,
      handleClose,
      setCurrentUser
    ]
  );

  const _renderAddress = dataInfo => {
    let text = dataInfo ? 'Sin dirección' : null;
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    if (dataInfo.city) {
      text = dataInfo.city;
    }
    if (dataInfo.city && dataInfo.department) {
      text = `${text}, `;
    }
    if (dataInfo.department) {
      text = text + dataInfo.department;
    }
    if (dataInfo.address) {
      text = `${text} ${dataInfo.address}`;
    }

    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const _renderName = text => {
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const onChangeAliasName = useCallback(event => {
    setDirty({ aliasName: true });
    setAliasName(event.target.value);
  }, []);

  const onChangeAliasIcon = useCallback(event => {
    setAliasIcon(event.target.value);
  }, []);

  useEffect(() => {
    try {
      editContractSchema.validateSync({ aliasName, aliasIcon });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [aliasName, aliasIcon]);

  useEffect(() => {
    if (contract) {
      if (INVOICE_CONTRACTS.includes(contract.type)) {
        setAliasIcon(contract.type);
        setAliasName(DEFAULT_ALIASES[contract.type]);
      } else {
        setAliasIcon(4);
        setAliasName('Especial');
      }
    }
  }, [contract]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={handleAssociate}
          type="submit"
          form="editContractForm"
          color="primary"
          disabled={!canSubmit || loading}
          size="small"
          autoFocus
        >
          Asociar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        <div className={classes.contractDataContainer}>
          <Typography className={classes.subText}>
            Dirección del predio
          </Typography>
          {_renderAddress(contract)}
          <Typography className={classes.subText}>
            Nombre del titular
          </Typography>
          {_renderName(
            contract ? fullName(contract.holder, 'name', 'lastName') : null
          )}
        </div>

        <Typography className={classes.text}>
          Selecciona un icono y un alias para identificar{' '}
          {Company.contractConjugation.regular.singular.article} de manera
          sencilla
        </Typography>
        <form id="editContractForm" onSubmit={handleAssociate}>
          <div className={classes.alias}>
            <SelectInput
              className={classes.aliasIcon}
              value={aliasIcon}
              onChange={onChangeAliasIcon}
              label={'Icono'}
              options={ICON_SELECT_OPTIONS}
              margin="none"
            />
            <TextInput
              className={classes.aliasText}
              value={aliasName}
              error={dirty.aliasName && Boolean(errors.aliasName)}
              helperText={dirty.aliasName && errors.aliasName}
              onChange={onChangeAliasName}
              label={`Alias de ${Company.contractConjugation.regular.singular.article}`}
              margin="none"
            />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={`Asociar ${Company.contractConjugation.regular.singular.main}`}
        actions={renderActions}
        content={renderContent}
        contentStyle={classes.dialogContent}
        fullScreen={smallScreen}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13,
      marginBottom: theme.spacing(2)
    }
  },
  introText: {
    marginBottom: theme.spacing()
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  dialogContent: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2)
    }
  },
  contractDataContainer: { marginBottom: theme.spacing(1.5), width: '100%' },
  labelText: {
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  subText: {
    alignSelf: 'flex-start',
    display: 'inline',
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  alias: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  aliasIcon: {
    minWidth: 67
  },
  aliasText: {
    minWidth: 150,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      minWidth: 250
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  divider: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    }
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  switchText: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: 12,
      marginBottom: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

export default ContractAssociationDialogGDG;
