import React, { useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { RecaptchaLink } from '../../Configs/Links';

const RecaptchaDisclaimer = props => {
  const { loading, setLoading } = props;

  const classes = useStyles();

  useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        setLoading(false);
      });
    }
  }, [setLoading]);

  if (loading) {
    return (
      <Typography className={classes.text}>Cargando ReCaptcha ...</Typography>
    );
  }

  return (
    <Typography className={classes.text}>
      Este sitio está protegido por reCAPTCHA y aplican las{' '}
      <a
        href={RecaptchaLink.Privacy}
        className={classes.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        políticas de privacidad
      </a>{' '}
      y los{' '}
      <a
        href={RecaptchaLink.Terms}
        className={classes.link}
        rel="noopener noreferrer"
        target="_blank"
      >
        términos de servicio
      </a>{' '}
      de Google.
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 10,
    color: theme.typography.color.light
  },
  link: {
    color: theme.palette.primary.dark
  }
}));

export default RecaptchaDisclaimer;
