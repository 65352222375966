import { Grid, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useState } from 'react';
import TextInput from '../../../Components/Inputs/TextInput';
import ShowPassword from '../../../Components/Adornments/ShowPassword';

// eslint-disable-next-line complexity
const UserForm = ({ classes, register, errors }) => {
  const [passwordShown, setShowPassword] = useState(false);
  const [confirmPasswordShown, setShowConfirmPassword] = useState(false);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const toggleConfirmPasswordShow = useCallback(() => {
    setShowConfirmPassword(!confirmPasswordShown);
  }, [confirmPasswordShown]);

  return (
    <Fragment>
      <Typography variant="subtitle2" className={classes.subtitle}>
        Ingresa tus datos de usuario
      </Typography>
      <Grid container direction="row" className={classes.section}>
        <Grid item xs={12} className={classes.inputContainer}>
          <TextInput
            id="Signup_input_email"
            name="email"
            label="Correo electrónico"
            inputRef={register}
            className={
              Boolean(errors.email) ? classes.inputError : classes.input
            }
            helperText={errors.email && errors.email.message}
            error={Boolean(errors.email)}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.inputContainer}>
          <TextInput
            id="Signup_input_password"
            inputRef={register}
            className={
              Boolean(errors.password) ? classes.inputError : classes.input
            }
            type={passwordShown ? 'text' : 'password'}
            name="password"
            label="Contraseña"
            InputProps={{
              endAdornment: (
                <ShowPassword
                  passwordShown={passwordShown}
                  togglePasswordShow={togglePasswordShow}
                />
              )
            }}
            helperText={errors.password && errors.password.message}
            error={Boolean(errors.password)}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.inputContainer}>
          <TextInput
            id="Signup_input_confirmPassword"
            inputRef={register}
            className={
              Boolean(errors.confirmPassword)
                ? classes.inputError
                : classes.input
            }
            type={confirmPasswordShown ? 'text' : 'password'}
            name="confirmPassword"
            label="Confirmar contraseña"
            InputProps={{
              endAdornment: (
                <ShowPassword
                  passwordShown={confirmPasswordShown}
                  togglePasswordShow={toggleConfirmPasswordShow}
                />
              )
            }}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            error={Boolean(errors.confirmPassword)}
            margin="none"
            fullWidth
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserForm;
