import React, { Fragment, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, CircularProgress } from '@material-ui/core';

const BaseTab = withStyles(theme => ({
  root: {
    fontSize: 16,
    [theme.breakpoints.down(theme.breakpoints.values.xm)]: {
      fontSize: 14
    },
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeight,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: '#2366C9',
      opacity: 1
    },
    '&$selected': {
      color: '#2366C9',
      fontWeight: 700
    },
    '&:focus': {
      color: '#2366C9'
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 3,
    borderBottomColor: '#2366C9'
  }
})(Tabs);

const BaseTabs = props => {
  const {
    id = 'BaseTabs_id',
    loading = false,
    tabs = [null],
    contents = null,
    variant = 'scrollable',
    scrollButtons = 'auto',
    className,
    onChange = null,
    ...other
  } = props;

  const classes = useStyles();

  const [value, setValue] = useState(tabs[0].value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <Fragment>
      <StyledTabs
        id={id}
        value={value}
        onChange={handleChange}
        variant={variant}
        scrollButtons={scrollButtons}
        className={className}
        {...other}
      >
        {tabs.map((tab, index) => (
          <BaseTab key={index} value={tab.value} label={tab.label}></BaseTab>
        ))}
      </StyledTabs>
      {loading && (
        <div className={classes.progressContainer}>
          <CircularProgress />
        </div>
      )}
      {!loading &&
        contents.map((content, index) => (
          <div key={index} hidden={content.value !== value}>
            <content.element {...content.props} />
          </div>
        ))}
    </Fragment>
  );
};

const useStyles = makeStyles({
  progressContainer: {
    display: 'flex',
    height: 200,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default BaseTabs;
