import React from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../../Components/Transitions/Transitions';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { WrongContractDataModals } from './ContractAssociation';

function ContractAssociationNoCheckedModalConfirmGDC(props) {
  const {
    open = false,
    controlWrongContractDataModal,
    setRedirectPage,
    contractYesChecked,
    redirectPage
  } = props;
  const classes = useStyles();

  const handleContinue = () => {
    contractYesChecked();
    controlWrongContractDataModal(WrongContractDataModals.Confirm, false);
  };

  const handleBack = () => {
    setRedirectPage('');
    controlWrongContractDataModal(WrongContractDataModals.SelectOptions, true);
    controlWrongContractDataModal(WrongContractDataModals.Confirm, false);
  };

  const renderConfirmSelection = () => {
    return (
      <Box className={classes.textContent}>
        <Typography className={classes.itemText}>
          Al finalizar la asociación del contrato serás redirigido <br />
          al módulo de{' '}
          {redirectPage === ROUTE_NAMES.ownership ? 'Cambio de titular' : 'PQR'}
          .
        </Typography>
      </Box>
    );
  };

  const renderActions = () => {
    return (
      <>
        <BaseButton
          onClick={handleBack}
          variant="outlined"
          color="primary"
          size="small"
        >
          Volver
        </BaseButton>
        <BaseButton
          onClick={handleContinue}
          color="primary"
          size="small"
          autoFocus
        >
          Continuar
        </BaseButton>
      </>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      handleClose={() =>
        controlWrongContractDataModal(WrongContractDataModals.Confirm, false)
      }
      title="Datos incorrectos"
      content={renderConfirmSelection}
      actions={renderActions}
      fullScreen={isMobileSize}
      contentStyle={classes.content}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
}

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'inherit',
    maxWidth: '100%',
    paddingTop: theme.spacing(2)
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '115px',
    margin: theme.spacing(2),
    justifyContent: 'space-between'
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  button: {
    padding: theme.spacing(1)
  }
}));

export default withWidth()(ContractAssociationNoCheckedModalConfirmGDC);
