import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import * as yup from 'yup';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import Title from '../../../Components/Labels/Title';
import TextInput from '../../../Components/Inputs/TextInput';
import SelectInput from '../../../Components/Inputs/SelectInput';
import BaseButton from '../../../Components/Buttons/BaseButton';
import YesNoButton from '../../../Components/Buttons/YesNoButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';

import QuestionMark from '../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';

import FooterPayment from '../../Payment/FooterPayment';
import ContractCard from '../../../Components/Cards/ContractCard';

import { history } from '../../../Routes/history';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  DEFAULT_ALIASES,
  ICON_SELECT_OPTIONS,
  INVOICE_CONTRACTS
} from '../contract_enums';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { HELP_TEXT } from '../../../Utils/enums';

// * API
import {
  GetSingleContractAPI,
  PostAssociateContract
} from '../../../API/Contracts/ContractsAPI';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { ContractsRefreshContext } from '../../../Contexts/ContractsContext';

import RecaptchaDisclaimer from '../../../Components/Captcha/RecaptchaDisclaimer';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { Company } from '../../../Configs/general';
import { InvoiceHelpType } from '../../../Enums/invoices';
import { logoutUser } from '../../../Utils/User/Actions';

const aliasSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Debe ser válido.')
});

const ContractAssociationGDG = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);
  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [number, setNumber] = useState('');
  const [loadingContract, setLoadingContract] = useState(false);
  const [contract, setContract] = useState(null);
  const [isContract, setIsContract] = useState(false);
  const [aliasIcon, setAliasIcon] = useState(1);
  const [aliasName, setAliasName] = useState('');
  const [sendEmailInvoice, setSendInvoice] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [errors, setErrors] = useState({
    aliasName: null
  });
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  // * OTHER HOOKS
  const classes = useStyles();
  const isContractRef = useRef(null);
  const aliasRef = useRef(null);

  // * LAYOUT EFFECTS
  useLayoutEffect(() => {
    // Scroll to "is this your contract" Toggle button
    if (contract) {
      isContractRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contract]);

  useEffect(() => {
    if (!currentUser) {
      history.replace('/');
      return;
    }
  }, [currentUser]);

  useEffect(() => {
    if (!aliasName || !isContract) {
      setCanSubmit(false);
      return;
    }

    try {
      aliasSchema.validateSync({ aliasName });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [isContract, aliasName]);

  useEffect(() => {
    if (contract) {
      if (INVOICE_CONTRACTS.includes(contract.type)) {
        setAliasIcon(contract.type);
        setAliasName(DEFAULT_ALIASES[contract.type]);
      } else {
        setAliasIcon(4);
        setAliasName('Especial');
      }
    }
  }, [contract]);

  // * QUERY SINGLE CONTRACT
  const _resetQueryData = useCallback(() => {
    setContract(null);
    setLoadingContract(false);
    setIsContract(false);
    setSendInvoice(false);
  }, []);

  useEffect(() => {
    _resetQueryData();
  }, [number, _resetQueryData]);

  const _handleQuery = useCallback(
    async event => {
      event.preventDefault();

      _resetQueryData();

      setLoadingContract(true);
      const response = await GetSingleContractAPI(number, currentUser.token);
      if (response.success) {
        setContract(response.data.data);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoadingContract(false);
    },
    [_resetQueryData, currentUser, number, setCurrentUser, setAlert]
  );

  // * ASSOCIATE CONTRACT
  const _handleAssociate = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const newContract = {
      alias: aliasName.trim(),
      icon: aliasIcon,
      sendInvoice: sendEmailInvoice,
      sendEmailInvoice,
      origin: {
        url: window.location.pathname
      }
    };

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaAction.AssociateContract
    );

    const response = await PostAssociateContract(
      currentUser.token,
      number,
      newContract,
      recaptchaToken
    );
    if (response.success) {
      setAlert({
        type: 'success',
        message: `Se ha asociado ${
          Company.contractConjugation.regular.singular.article
        } "${aliasName.trim()}"`
      });
      refreshContracts();
      history.push('/');
    } else {
      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
      setLoading(false);
    }
  }, [
    currentUser,
    number,
    aliasName,
    aliasIcon,
    sendEmailInvoice,
    refreshContracts,
    setCurrentUser,
    setAlert
  ]);

  // OTHER FUNCTIONS
  const contractYesChecked = useCallback(() => {
    setIsContract(true);
  }, []);
  const contractNoChecked = useCallback(() => {
    setIsContract(false);
  }, []);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const steps = useMemo(() => {
    const usedSteps = [
      {
        label: `Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`,
        onBack: () => history.goBack(),
        action: { text: 'Asociar', onCall: _handleAssociate }
      }
    ];
    return usedSteps;
  }, [_handleAssociate]);

  return (
    <div className={classes.root}>
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      {loading && <FullSizeProgress />}
      {loadingContract && <ModalProgress />}
      <Container maxWidth="md">
        <Title
          text={`Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`}
        />
        <form onSubmit={_handleQuery}>
          <Grid container>
            <Grid item sm={4} xs={12} className={classes.inputsContainer}>
              <TextInput
                autoFocus
                fullWidth
                value={number}
                onChange={e => setNumber(e.target.value)}
                disabled={loading}
                label={`Número de ${Company.contractConjugation.regular.singular.main}`}
                InputProps={{
                  endAdornment: <QuestionMark onClick={onHelpClick} />,
                  inputProps: {
                    type: 'number',
                    inputMode: 'numeric'
                  }
                }}
                required={true}
              />
              <BaseButton
                disabled={loading || loadingCaptcha || !number}
                fullWidth={true}
                color="primary"
                variant="outlined"
                type="submit"
                className={classes.button}
              >
                Consultar
              </BaseButton>
              <div className={classes.disclaimerContainer}>
                <RecaptchaDisclaimer
                  loading={loadingCaptcha}
                  setLoading={setLoadingCaptcha}
                />
              </div>
            </Grid>
            <Grid item sm={8} xs={12} className={classes.contractContainer}>
              <ContractCard
                data={contract}
                className={classes.contract}
                loading={loadingContract}
              />
            </Grid>
          </Grid>
        </form>
        {contract && (
          <Fragment>
            <div className={classes.itemContainer}>
              <Typography className={classes.itemText} ref={isContractRef}>
                ¿La información visualizada de{' '}
                {Company.contractConjugation.regular.singular.article} es la
                correcta?
              </Typography>
              <YesNoButton
                checked={isContract}
                yesChecked={contractYesChecked}
                noChecked={contractNoChecked}
                color="primary"
                small="small"
              />
            </div>
            <SimpleDivider withoutMargin className={classes.itemDivider} />
            {isContract && (
              <Fragment>
                <div className={classes.itemInputContainer}>
                  <Typography
                    className={`${classes.itemText} ${classes.itemInputText}`}
                    ref={aliasRef}
                  >
                    Agrega un alias a tu{' '}
                    {Company.contractConjugation.regular.singular.main}, para
                    que luego puedas identificarlo más fácil
                  </Typography>
                  <div className={classes.itemAliasContainer}>
                    <SelectInput
                      className={classes.itemInputIcon}
                      value={aliasIcon}
                      onChange={e => setAliasIcon(e.target.value)}
                      disabled={loading}
                      label={'Icono'}
                      options={ICON_SELECT_OPTIONS}
                    />
                    <TextInput
                      id={'alias_input'}
                      className={classes.itemInputAlias}
                      value={aliasName}
                      error={Boolean(errors.aliasName)}
                      helperText={errors.aliasName}
                      onChange={e => setAliasName(e.target.value)}
                      disabled={loading}
                      label={`Alias de ${Company.contractConjugation.regular.singular.article}`}
                      required={true}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>
      <FooterPayment currentStep={steps[0]} disabledNext={!canSubmit} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: theme.spacing(3),
    marginBottom: theme.custom.footerHeight.stepper,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6)
    }
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 20
  },
  contractContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  itemText: {
    fontSize: 14,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0
    }
  },
  itemInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    }
  },
  itemInputText: {
    fontWeight: 500
  },
  itemAliasContainer: {
    flex: 1,
    display: 'flex',
    // flexWrap: 'nowrap',
    justifyContent: 'flex-end'
  },
  itemInputIcon: {
    minWidth: 67
  },
  itemInputAlias: {
    minWidth: 200,
    marginLeft: theme.spacing(2)
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  },
  disclaimerContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
}));

export default ContractAssociationGDG;
