import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import * as yup from 'yup';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import Title from '../../../Components/Labels/Title';
import TextInput from '../../../Components/Inputs/TextInput';
import BaseButton from '../../../Components/Buttons/BaseButton';
import YesNoButton from '../../../Components/Buttons/YesNoButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';

import QuestionMark from '../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';

import FooterPayment from '../../Payment/FooterPayment';
import ContractCard from '../../../Components/Cards/ContractCard';

import { history } from '../../../Routes/history';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { DEFAULT_ALIASES, INVOICE_CONTRACTS } from '../contract_enums';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { HELP_TEXT } from '../../../Utils/enums';

// * API
import {
  GetSingleContractAPI,
  PostAssociateContract
} from '../../../API/Contracts/ContractsAPI';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import {
  ContractsContext,
  ContractsRefreshContext
} from '../../../Contexts/ContractsContext';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import RecaptchaDisclaimer from '../../../Components/Captcha/RecaptchaDisclaimer';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import ContractAssociationForm from './ContractAssociationForm/ContractAssociationForm';
import { Company } from '../../../Configs/general';
import { InvoiceHelpType } from '../../../Enums/invoices';
import { logoutUser } from '../../../Utils/User/Actions';

const associateSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono')
});

const ContractAssociationEFG = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);
  const setAlert = useContext(AlertsDispatchContext);
  const userContracts = useContext(ContractsContext);

  // * STATE HOOKS
  const [aliasIcon, setAliasIcon] = useState(1);
  const [aliasName, setAliasName] = useState('');
  const [sendInvoice, setSendInvoice] = useState(true);
  const [digitalInvoice, setDigitalInvoice] = useState();
  const [digitalChannels, setDigitalChannels] = useState({
    email: true,
    whatsapp: false,
    sms: false
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [number, setNumber] = useState('');
  const [loadingContract, setLoadingContract] = useState(false);
  const [contract, setContract] = useState(null);
  const [isContract, setIsContract] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [needsPhoneValidation, setNeedsPhoneValidation] = useState(false);
  const [openPhoneValidationDialog, setOpenPhoneValidationDialog] = useState(
    false
  );
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [phoneActionText, setPhoneActionText] = useState('Validar');

  // * OTHER HOOKS
  const classes = useStyles();
  const isContractRef = useRef(null);

  const allowedContractType =
    contract && INVOICE_CONTRACTS.includes(contract.type);

  // * LAYOUT EFFECTS
  useLayoutEffect(() => {
    // Scroll to "is this your contract" Toggle button
    if (contract) {
      isContractRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contract]);

  useEffect(() => {
    if (!currentUser) {
      history.replace('/');
      return;
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isContract) {
      setCanSubmit(false);
      return;
    }

    try {
      associateSchema.validateSync({ aliasName, aliasIcon });
      setErrors({});
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [isContract, aliasName, aliasIcon, sendInvoice, digitalInvoice]);

  useEffect(() => {
    if (contract) {
      if (INVOICE_CONTRACTS.includes(contract.type)) {
        setAliasIcon(contract.type);
        setAliasName(DEFAULT_ALIASES[contract.type]);
      } else {
        setAliasIcon(4);
        setAliasName('Especial');
      }
    }
  }, [contract]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (!currentUser.phone && !currentUser.phoneCountryCode) {
      setPhoneActionText('Agregar');
      return;
    }

    if (!currentUser.phoneValidated) {
      setPhoneActionText('Confirmar');
    }
  }, [currentUser]);

  const onChangeAliasName = useCallback(value => {
    setAliasName(value);
    setErrors(errs => ({ ...errs, aliasName: null }));
  }, []);

  const resetDigitalInvoiceForm = useCallback(() => {
    setSendInvoice(true);
    setDigitalChannels({ email: true, whatsapp: false, sms: false });
    setDigitalInvoice();
  }, []);

  // * QUERY SINGLE CONTRACT
  const _resetQueryData = useCallback(() => {
    setContract(null);
    setLoadingContract(false);
    setIsContract(false);
    resetDigitalInvoiceForm();
  }, [resetDigitalInvoiceForm]);

  useEffect(() => {
    _resetQueryData();
  }, [number, _resetQueryData]);

  const _handleQuery = useCallback(
    async event => {
      event.preventDefault();
      _resetQueryData();

      if (userContracts.find(({ id }) => id === parseInt(number, 10))) {
        setAlert({
          type: 'error',
          message: `${Company.contractConjugation.capitalized.singular.article} ya está asociado a tu cuenta`
        });
        return;
      }

      setLoadingContract(true);
      const response = await GetSingleContractAPI(number, currentUser.token);
      if (response.success) {
        const contractData = response.data.data;
        setContract(contractData);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoadingContract(false);
    },
    [
      _resetQueryData,
      currentUser,
      number,
      setCurrentUser,
      setAlert,
      userContracts
    ]
  );

  // * ASSOCIATE CONTRACT
  const _handleAssociate = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const contractHasDigitalInvoice = contract.digitalInvoice;

    // If the contract has an active digital invoice,
    // the user's response is taken as sendInvoice.
    // If it does not have it active, sendInvoice will
    // be the same as digitalInvoice.

    const realSendInvoice = contractHasDigitalInvoice
      ? sendInvoice
      : digitalInvoice;

    const realDigitalInvoice = contractHasDigitalInvoice || digitalInvoice;

    const digitalInvoiceSettings = {
      digitalInvoice: realDigitalInvoice,
      sendInvoice: realSendInvoice,
      sendEmailInvoice: realSendInvoice,
      sendWhatsAppInvoice: false,
      sendSmsInvoice: realSendInvoice && digitalChannels.sms
    };

    // When the contract to associate is not an
    // allowed one for digital invoice, it sets
    // every digital invoice setting to false
    if (!allowedContractType) {
      for (const key of Object.keys(digitalInvoiceSettings)) {
        digitalInvoiceSettings[key] = false;
      }
    }

    const newAssociation = {
      ...digitalInvoiceSettings,
      alias: aliasName.trim(),
      icon: aliasIcon,
      origin: {
        url: window.location.pathname
      }
    };

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaAction.AssociateContract
    );

    const response = await PostAssociateContract(
      currentUser.token,
      number,
      newAssociation,
      recaptchaToken
    );
    if (response.success) {
      setAlert({
        type: 'success',
        message: `Se ha asociado ${
          Company.contractConjugation.regular.singular.article
        } "${aliasName.trim()}"`
      });
      refreshContracts();
      history.push('/');
      return { unmounting: true };
    }
    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true };
    }
    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    setLoading(false);
    return { unmounting: false, closeDialog: true };
  }, [
    currentUser,
    number,
    aliasName,
    aliasIcon,
    refreshContracts,
    setCurrentUser,
    setAlert,
    digitalInvoice,
    digitalChannels,
    sendInvoice,
    contract,
    allowedContractType
  ]);

  // OTHER FUNCTIONS
  const closePhoneDialog = useCallback(() => {
    setOpenPhoneValidationDialog(false);
  }, []);

  const contractYesChecked = useCallback(() => {
    setIsContract(true);
  }, []);

  const contractNoChecked = useCallback(() => {
    if (isContract) {
      setIsContract(false);
      resetDigitalInvoiceForm();
    }
  }, [isContract, resetDigitalInvoiceForm]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const onSubmitAssociate = useCallback(() => {
    if (needsPhoneValidation) {
      setOpenPhoneValidationDialog(true);
      return;
    }

    _handleAssociate();
  }, [needsPhoneValidation, _handleAssociate]);

  const steps = useMemo(() => {
    const usedSteps = [
      {
        label: `Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`,
        onBack: () => history.goBack(),
        action: { text: 'Asociar', onCall: onSubmitAssociate }
      }
    ];
    return usedSteps;
  }, [onSubmitAssociate]);

  return (
    <div className={classes.root}>
      {openPhoneValidationDialog && (
        <RequestDialog
          open={openPhoneValidationDialog}
          setDialog={setOpenPhoneValidationDialog}
          beforeClosing={closePhoneDialog}
          requestTitle={`${phoneActionText} número celular`}
          requestCallback={_handleAssociate}
          withoutObservation={true}
          backButton={{
            text: 'Cancelar',
            handle: closePhoneDialog
          }}
          submitButtonText={'Continuar'}
          requestNode={
            <Typography paragraph className={classes.text}>
              {`Para llevar a cabo esta acción es necesario ${phoneActionText.toLowerCase()} tu número de celular`}
            </Typography>
          }
          contentSize="small"
        />
      )}
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      {loading && <FullSizeProgress />}
      {loadingContract && <ModalProgress />}
      <Container maxWidth="md">
        <Title
          text={`Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`}
        />
        <form onSubmit={_handleQuery}>
          <Grid container>
            <Grid item sm={4} xs={12} className={classes.inputsContainer}>
              <TextInput
                autoFocus
                fullWidth
                value={number}
                onChange={e => setNumber(e.target.value)}
                disabled={loading}
                label={`Número de ${Company.contractConjugation.regular.singular.main}`}
                InputProps={{
                  endAdornment: <QuestionMark onClick={onHelpClick} />,
                  inputProps: {
                    type: 'number',
                    inputMode: 'numeric'
                  }
                }}
                required={true}
              />
              <BaseButton
                disabled={loading || loadingCaptcha || !number}
                fullWidth={true}
                color="primary"
                variant="outlined"
                type="submit"
                className={classes.button}
              >
                Consultar
              </BaseButton>
              <div className={classes.disclaimerContainer}>
                <RecaptchaDisclaimer
                  loading={loadingCaptcha}
                  setLoading={setLoadingCaptcha}
                />
              </div>
            </Grid>
            <Grid item sm={8} xs={12} className={classes.contractContainer}>
              <ContractCard
                data={contract}
                className={classes.contract}
                loading={loadingContract}
              />
            </Grid>
          </Grid>
        </form>
        {contract && (
          <Fragment>
            <div className={classes.itemContainer}>
              <Typography className={classes.itemText} ref={isContractRef}>
                ¿La información visualizada{' '}
                {Company.contractConjugation.regular.singular.contraction} es la
                correcta?
              </Typography>
              <YesNoButton
                checked={isContract}
                yesChecked={contractYesChecked}
                noChecked={contractNoChecked}
                color="primary"
                small="small"
              />
            </div>
            <SimpleDivider withoutMargin className={classes.itemDivider} />

            {isContract && (
              <ContractAssociationForm
                currentUser={currentUser}
                contract={contract}
                sendInvoice={sendInvoice}
                setSendInvoice={setSendInvoice}
                isDigitalInvoice={digitalInvoice}
                setIsDigitalInvoice={setDigitalInvoice}
                digitalChannels={digitalChannels}
                setDigitalChannels={setDigitalChannels}
                aliasName={aliasName}
                setAliasName={onChangeAliasName}
                aliasIcon={aliasIcon}
                setAliasIcon={setAliasIcon}
                errors={errors}
                needsPhoneValidation={needsPhoneValidation}
                setNeedsPhoneValidation={setNeedsPhoneValidation}
                setCanSubmit={setCanSubmit}
                allowedContractType={allowedContractType}
              />
            )}
          </Fragment>
        )}
      </Container>
      <FooterPayment currentStep={steps[0]} disabledNext={!canSubmit} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: theme.spacing(3),
    marginBottom: theme.custom.footerHeight.stepper,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6)
    }
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    marginTop: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 20
  },
  contractContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingBottom: 0
    }
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  },
  disclaimerContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  }
}));

export default ContractAssociationEFG;
