import _map from 'lodash/map';

export function objectToString(object) {
  let str = '';
  str = _map(object, (value, key) => {
    return `${key}: ${typeof value == 'string' ? value : value.join('')}`;
  });
  return str;
}

/**
 * Maps the given object to an array of labeling objects.
 *
 * @param {Object} options an object where each key represents a value for which the label will be generated.
 * @returns {Array} the array of objects with {value, label}
 */
export function buildLabelValues(options) {
  return Object.entries(options).map(([value, label]) => ({ value, label }));
}
