import { buildLabelValues } from '../Utils/Format/Objects';

export const SignInMethod = {
  Password: 'password',
  Social: 'social'
};

export const USER_TYPE = {
  NaturalPerson: 'natural-person',
  LegalPerson: 'legal-person'
};

export const IDENTIFICATION_TYPE = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE',
  TI: 'TI',
  NIT: 'NIT'
};

const IDENTIFICATION_OPTION_SHORT_LABEL = {
  [IDENTIFICATION_TYPE.CC]: 'CC',
  [IDENTIFICATION_TYPE.PP]: 'PP',
  [IDENTIFICATION_TYPE.CE]: 'CE',
  [IDENTIFICATION_TYPE.TI]: 'TI'
};

const IDENTIFICATION_OPTION_FULL_LABEL = {
  [IDENTIFICATION_TYPE.CC]: 'Cédula',
  [IDENTIFICATION_TYPE.PP]: 'Pasaporte',
  [IDENTIFICATION_TYPE.CE]: 'Cédula de extranjería',
  [IDENTIFICATION_TYPE.TI]: 'Tarjeta de identidad'
};

// Note that all options are missing the NIT identification type
// because such type is reserved only for legal entities which are handled appart.
export const IDENTIFICATION_TYPE_LABELS = {
  Full: buildLabelValues(IDENTIFICATION_OPTION_FULL_LABEL),
  Short: buildLabelValues(IDENTIFICATION_OPTION_SHORT_LABEL)
};

export const isUserLoggedInWithPassword = user => {
  return user && user.signInMethod === SignInMethod.Password;
};

export const isUserLoggedInWithSocial = user => {
  return user && user.signInMethod === SignInMethod.Social;
};
