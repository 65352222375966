import { Grid } from '@material-ui/core';
import React from 'react';
import TextInput from '../../../Components/Inputs/TextInput';

// eslint-disable-next-line complexity
const LegalPersonForm = ({ classes, register, errors }) => {
  return (
    <Grid container direction="row" className={classes.formSection}>
      <Grid item xs={12} className={classes.inputContainer}>
        <TextInput
          id="Signup_LegalPersonForm_input_businessName"
          name="businessName"
          label="Razón social"
          inputRef={register}
          className={
            Boolean(errors.businessName) ? classes.inputError : classes.input
          }
          helperText={errors.businessName && errors.businessName.message}
          error={Boolean(errors.businessName)}
          margin="none"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={8} className={classes.inputContainer}>
        <TextInput
          id="Signup_LegalPersonForm_input_businessIdentification"
          name="businessIdentification"
          label="NIT"
          inputRef={register}
          className={
            Boolean(errors.businessIdentification)
              ? classes.inputError
              : classes.input
          }
          helperText={
            errors.businessIdentification &&
            errors.businessIdentification.message
          }
          error={Boolean(errors.businessIdentification)}
          margin="none"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4} className={classes.inputContainer}>
        <TextInput
          id="Signup_LegalPersonForm_input_checkDigit"
          name="checkDigit"
          label="Dígito de verificación"
          inputRef={register}
          className={
            Boolean(errors.checkDigit) ? classes.inputError : classes.input
          }
          helperText={errors.checkDigit && errors.checkDigit.message}
          error={Boolean(errors.checkDigit)}
          margin="none"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default LegalPersonForm;
