import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import UserWithContract from './UserWithContract';
import UserWithoutContract from './UserWithoutContract';

import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';

import ControlledYesNoButton from '../../../../Components/Controlled/ControlledYesNoButton';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { UserContext } from '../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';
import { ContractsContext } from '../../../../Contexts/ContractsContext';

import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { GetSingleContractAPI } from '../../../../API/Contracts/ContractsAPI';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Company } from '../../../../Configs/general';

const schema = yup.object({
  contractUser: yup
    .string()
    .oneOf(['0', '1'])
    .required(),
  contract: yup.mixed().when('contractUser', {
    is: '1',
    then: yup
      .object({
        id: yup
          .number()
          .required(
            `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
          )
          .typeError(
            `El número de ${Company.contractConjugation.regular.singular.main} debe ser válido`
          )
          .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value;
          }),
        label: yup.string()
      })
      .required(
        `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
      ),
    otherwise: yup.mixed()
  }),
  procedureType: yup.mixed().when('contractUser', {
    is: '0',
    then: yup
      .number()
      .required()
      .integer()
      .oneOf([1, 2, 3, 4]),
    otherwise: yup.mixed()
  }),
  location: yup
    .string()
    .max(80, 'Máximo 80 carácteres')
    .required('Ingresa la ciudad'),
  address: yup
    .string()
    .max(100, 'Máximo 100 carácteres')
    .required('Ingresa la dirección')
});

const ServiceStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText
  } = props;

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const currentUser = useContext(UserContext);

  const contracts = useContext(ContractsContext);

  const CONTRACT_OPTIONS = useMemo(() => {
    return contracts
      ? contracts.map(contract => {
          return { id: contract.id, label: contract.alias };
        })
      : [];
  }, [contracts]);

  const {
    register,
    watch,
    setValue,
    errors,
    setError,
    clearError,
    control,
    handleSubmit
  } = useForm({
    validationSchema: schema,
    defaultValues: {
      contractUser: '1',
      procedureType: 1,
      personalData: false,
      ...stepperData
    }
  });

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [contractData, setContractData] = useState(
    stepperData.contract ? { ...stepperData.contractData } : null
  );
  const [contractSearched, setContractSearched] = useState(
    stepperData.contract ? String(stepperData.contract.id) : ''
  );
  const [contractOptions, setContractOptions] = useState(CONTRACT_OPTIONS);

  const setAlert = useContext(AlertsDispatchContext);

  const authToken = currentUser ? currentUser.token : null;

  const searchContract = watch('contract');
  const formData = watch(['location', 'address']);
  const isContractUser = Boolean(parseInt(watch('contractUser'), 10));

  // Register non-input form fields
  useEffect(() => {
    if (isContractUser) {
      register('location');
      register('address');
    }
  }, [register, isContractUser]);

  // set canSubmit
  useEffect(() => {
    if (
      (isContractUser && (!captcha || loadingCaptcha || !contractData)) ||
      loading
    ) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [
    captcha,
    contractData,
    isContractUser,
    loadingCaptcha,
    loading,
    setCanSubmit
  ]);

  // footer nav setup
  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({});
  }, [setGoBack, setVisualStepperIndex, currentStepIndex, setNextButtonText]);

  const onForward = useCallback(
    values => {
      if (isContractUser) {
        setData(data => ({ ...data, ...values, contractData }));
      } else {
        setData(data => ({ ...data, ...values }));
      }

      setCurrentStep(step => step + 1);
    },
    [isContractUser, contractData, setData, setCurrentStep]
  );

  const changeHandler = useCallback(
    ([_, value]) => {
      setValue('location', '');
      setValue('address', '');
      setContractSearched('');
      setContractData(null);

      return value;
    },
    [setValue]
  );

  const inputChangeHandler = useCallback(
    event => {
      if (event) {
        const contractId = event.target.value
          ? parseInt(event.target.value, 10)
          : '';

        setValue('contract', {
          id: contractId,
          label: ''
        });
        setValue('location', '');
        setValue('address', '');
        setContractData(null);
        setContractSearched('');

        const filteredContracts = CONTRACT_OPTIONS.filter(contractOption =>
          String(contractOption.id).includes(contractId)
        );

        setContractOptions(filteredContracts);
      }
    },
    [setValue, CONTRACT_OPTIONS]
  );

  const clickHandler = useCallback(() => {
    if (searchContract && !searchContract.id) {
      setValue('location', '');
      setValue('address', '');
      setContractData(null);
      return;
    }

    const fetchContract = async () => {
      setLoading(true);

      const response = await GetSingleContractAPI(
        searchContract.id,
        authToken,
        captcha
      );

      setContractSearched(searchContract.id);

      if (response.success) {
        clearError('contract');

        setContractData(response.data.data);

        const { city, address } = response.data.data;

        setValue('location', city);
        setValue('address', address);

        setLoading(false);
      } else {
        setValue('location', '');
        setValue('address', '');
        setContractData(null);

        setLoading(false);

        const errorMessage = extractErrorMessage(response).message;

        if (
          errorMessage ===
          `${Company.contractConjugation.capitalized.singular.article} no existe`
        ) {
          //TODO: Revisar por qué esto está como comentario
          //setError('contract', 'notFound', errorMessage);
        } else if (
          errorMessage ===
          `El número de ${Company.contractConjugation.regular.singular.main} debe ser válido`
        ) {
          setError('contract', 'type', errorMessage);
        } else {
          setAlert({
            type: 'error',
            message: errorMessage
          });
        }
      }
    };

    if (searchContract && contractSearched !== searchContract.id) {
      fetchContract();
    }
  }, [
    setValue,
    searchContract,
    clearError,
    authToken,
    setAlert,
    setError,
    contractSearched,
    captcha
  ]);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Datos del servicio</Typography>
      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <div className={classes.itemContainer}>
          <Typography className={classes.itemText}>
            ¿Eres usuario del servicio de Gas Natural?
          </Typography>
          <ControlledYesNoButton
            name="contractUser"
            control={control}
            onChange={changeHandler}
            disabled={loading}
          />
        </div>
        <SimpleDivider />
        <Typography className={classes.text}>
          Ingresa los siguientes datos:
        </Typography>
        <Grid container className={classes.inputContainer}>
          {isContractUser ? (
            <UserWithContract
              clickHandler={clickHandler}
              contract={contractData}
              inputChangeHandler={inputChangeHandler}
              errors={errors}
              formData={formData}
              loading={loading}
              control={control}
              contractOptions={contractOptions}
              setCaptcha={setCaptcha}
              setLoadingCaptcha={setLoadingCaptcha}
              disableSubmit={!captcha || loadingCaptcha || loading}
              contractSearched={contractSearched}
              authenticated={Boolean(authToken)}
            />
          ) : (
            <UserWithoutContract
              register={register}
              errors={errors}
              loading={loading}
              control={control}
            />
          )}
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(6)
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  text: {
    fontSize: 14
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  itemText: {
    fontSize: 16,
    textAlign: 'center',
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      textAlign: 'left',
      paddingRight: 0
    }
  },
  inputContainer: {
    marginTop: theme.spacing()
  },
  inputContainerItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  }
}));

export default ServiceStep;
