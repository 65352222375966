import { Grid } from '@material-ui/core';
import React from 'react';
import CompositeSelectInput from '../../../Components/Inputs/CompositeSelectInput';
import TextInput from '../../../Components/Inputs/TextInput';
import { IDENTIFICATION_TYPE_LABELS } from '../../../Enums/users';

// eslint-disable-next-line complexity
const NaturalPersonForm = ({
  classes,
  isMobileSize,
  control,
  register,
  errors
}) => {
  return (
    <Grid container direction="row" className={classes.formSection}>
      <Grid item xs={12} sm={6} className={classes.inputContainer}>
        <TextInput
          id="Signup_NaturalPersonForm_input_firstName"
          name="firstName"
          label="Nombres"
          inputRef={register}
          className={
            Boolean(errors.firstName) ? classes.inputError : classes.input
          }
          helperText={errors.firstName && errors.firstName.message}
          error={Boolean(errors.firstName)}
          margin="none"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.inputContainer}>
        <TextInput
          id="Signup_NaturalPersonForm_input_lastName"
          name="lastName"
          label="Apellidos"
          inputRef={register}
          className={
            Boolean(errors.lastName) ? classes.inputError : classes.input
          }
          helperText={errors.lastName && errors.lastName.message}
          error={Boolean(errors.lastName)}
          margin="none"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} className={classes.inputContainer}>
        <CompositeSelectInput
          inputId="Signup_NaturalPersonForm_input_identification"
          selectId="Signup_NaturalPersonForm_input_identificationType"
          className={
            Boolean(errors.identification) ? classes.inputError : classes.input
          }
          options={
            isMobileSize
              ? IDENTIFICATION_TYPE_LABELS.Short
              : IDENTIFICATION_TYPE_LABELS.Full
          }
          TextInputProps={{
            name: 'identification',
            label: 'Identificación',
            inputRef: register,
            fullWidth: true
          }}
          SelectInputProps={{
            name: 'identificationType',
            control
          }}
          error={Boolean(errors.identification)}
          helperText={errors.identification && errors.identification.message}
        />
      </Grid>
    </Grid>
  );
};

export default NaturalPersonForm;
